<template>
    <b-row> 
        <b-col md="12" class="my-1">
            <b-form-group  label-align-sm="right" label-size="md"
                label-for="filterInput" class="mb-0">
                <b-input-group size="md">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" /> 
                </b-input-group>
            </b-form-group>
        </b-col> 
        <b-col cols="12">
            <b-table   hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(tagihan)="data">
                    <b-row style="margin-left: -40px;" @click="reLodaData(data.item.id_periode_pembayaran)">
                         <b-col md="3">  
                            <b-avatar rounded size="60" variant="light-warning" v-if="st == 'Konfirmasi'">
                                        <span style="font-size:20px"> {{ data.item.jm }}</span>
                                    </b-avatar>

                                    <b-avatar rounded size="60" variant="light-primary" v-if="st == 'Valid'">
                                        <span style="font-size:20px"> {{ data.item.jm }}</span>
                                    </b-avatar>
                                    <b-avatar rounded size="60" variant="light-info" v-if="st == 'Revisi'">
                                        <span style="font-size:20px"> {{ data.item.jm }}</span>
                                    </b-avatar>

                                    <b-avatar rounded size="60" variant="light-danger" v-if="st == 'Ditolak'">
                                        <span style="font-size:20px"> {{ data.item.jm }}</span>
                                    </b-avatar>
                            </b-col>
                            
                            <b-col md="9"> 
                                <h6 class="transaction-title">
                                         {{ data.item.nm_jenis_pembayaran }} ({{ data.item.kelas }})
                                         <br>
                                         <small>{{ data.item.nama }}</small>
                                    </h6>
                                    <small>Periode pembayaran {{ data.item.tgl_mulai }} -  {{ data.item.tgl_selesai }} </small>
                         </b-col>
                    </b-row>
                </template>
            </b-table>
        </b-col>
        <b-col cols="6">
            <small><strong>Total Row {{ items.length }}</strong></small>
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        props : {
            items : {},
            st : {}
        },
        data() {
            return {
               
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'tagihan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }, ],
              
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        emits : ['ReLoadDataData'],

        methods: {
            reLodaData(val){
                this.$emit('ReLoadDataData', val)
            },
           
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>