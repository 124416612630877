<template>
  <div>
    <b-row>
      <b-col md="5">
        <belum-validasi :rs_jm=rs_jm @ReLoadData=ReLoadData></belum-validasi>
        <b-card class="card-transaction">

          <daftar-periode-pembayaran :items=list_data :st=st @ReLoadDataData=ReLoadDataData></daftar-periode-pembayaran>
        </b-card>
      </b-col>
      <b-col md="7">
        <b-card>
          <daftar-mahasiswa :st =st :items=detail_list @get_histori_pembayaran=get_histori_pembayaran></daftar-mahasiswa>
          <detail_pembayaran :histori_pembayaran=histori_pembayaran :rs_pembayaran=rs_detail
            @konfirmasiPembayaran=konfirmasiPembayaran></detail_pembayaran>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
  import Base from '@/config/Mixins_base'
  import axios from '@/config/Axios'
  import detail_pembayaran from './component/detail_pembayaran.vue'
  import {
    BRow,
    BCol,
    BCard,
  } from 'bootstrap-vue'
  import BelumValidasi from './component/belumValidasi.vue'
  import daftarPeriodePembayaran from './component/daftarPeriodePembayaran.vue'
  import DaftarMahasiswa from './component/DaftarMahasiswa.vue'
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BelumValidasi,
      daftarPeriodePembayaran,
      DaftarMahasiswa,
      detail_pembayaran
    },
    data() {
      return {
        rs_jm: {},
        list_data: [],
        detail_list: [],
        histori_pembayaran: {},
        st: 'Konfirmasi',
        id_periode_pembayaran: '',
        rs_detail: {}
      }
    },
    mixins: [Base],
    mounted() {
      this.load_count()
      this.load_data_list()
      this.load_detail_data()
    },
    methods: {
      konfirmasiPembayaran(id_tagihan, id_tahap_validasi, catatan) {
        this.insert_log(id_tagihan, id_tahap_validasi, catatan)
      },
      ReLoadData(val) {
        this.id_periode_pembayaran = ''
        this.st = val;
        this.load_data_list();
        this.load_detail_data();
      },
      ReLoadDataData(val) {
        this.id_periode_pembayaran = val;
        this.load_data_list();
        this.load_detail_data();
      },
      async load_count() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/pembayaran/validasi_pembayaran/load_count',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rs_jm = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async load_data_list() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/pembayaran/validasi_pembayaran/load_data_list',
            data: {
              status: self.st
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.list_data = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async load_detail_data() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/pembayaran/validasi_pembayaran/load_detail_data',
            data: {
              status: self.st,
              id_periode_pembayaran: self.id_periode_pembayaran
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.detail_list = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async get_histori_pembayaran(val) {
        this.rs_detail = val;
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/pembayaran/validasi_pembayaran/histori_pembayaran',
            data: {
              id_transaksi_pembayaran: val.id_transaksi_pembayaran
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.histori_pembayaran = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async insert_log(id_tagihan, id_tahap_validasi, catatan) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/pembayaran/validasi_pembayaran/insert_log',
            data: {
              id_transaksi_pembayaran: id_tagihan,
              id_tahap_validasi: id_tahap_validasi,
              catatan: catatan
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('info', "Info", response.data.message);
            self.load_data_list();
            self.load_detail_data();
            self.load_count();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }
  }
</script>

<style>

</style>