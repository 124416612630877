<template>
  <div>
    <b-alert variant=" " show cl>
      <div class="alert-body">
        <h4 class="text-warning"> <strong>
                            <feather-icon icon="LayersIcon" size="15" class="mr-1 " /> Histori
                            Pembayaran</strong></h4>
                    <hr>
        <app-timeline class=" mb-1">
          <!-- 12 INVOICES HAVE BEEN PAID -->
          <app-timeline-item>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Pengajuan Tagihan</h6>
            </div>
            <p>
              <small>
                {{ histori_pembayaran.log_1[0].user }} | {{ histori_pembayaran.log_1[0].dc }}
              </small>
            </p>
          </app-timeline-item>

          <app-timeline-item variant="secondary">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Konfirmasi Pembayaran</h6>
            </div>
            <h6 class="text-warning" v-if="histori_pembayaran.log_2 == '' "><i>**Menunggu Konvirmasi Pembayaran</i></h6>
            <ul v-if="histori_pembayaran.log_2 != '' ">
              <li v-for="rs_Data in histori_pembayaran.log_2" v-bind:key="rs_Data">
                <small> <strong>{{ rs_Data.catatan }}</strong><br>
                  {{ rs_Data.user }} | {{ rs_Data.dc }}
                </small>
              </li>
            </ul>
          </app-timeline-item>
          <!-- FINANCIAL REPORT -->
          <app-timeline-item variant="success">

            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Status Pembayaran</h6>
            </div>
            <div>
              <h6 class="text-warning" v-if="histori_pembayaran.log_3 == '' "><i>**Menunggu Validasi</i></h6>

            </div>
            <small v-if="histori_pembayaran.log_3 != '' "><strong>Note**</strong></small>
            <ul v-if="histori_pembayaran.log_3 != '' ">
              <li v-for="rs_Data in histori_pembayaran.log_3" v-bind:key="rs_Data">
                <small> <strong>{{ rs_Data.catatan }}</strong><br>
                  <strong class="text-primary"> {{ rs_Data.status }}</strong>
                  | {{ rs_Data.user }} | {{rs_Data.dc }}
                </small>
              </li>
            </ul>
          </app-timeline-item> 
          <app-timeline-item variant="success" v-if="rs_pembayaran.status =='Revisi'">
            <b-row>
              <b-col>
                <b-button size="sm" variant="outline-warning">   <feather-icon icon="EditIcon" size="15" />  Revisi
                </b-button>
              </b-col>
            </b-row>
          </app-timeline-item>
          <app-timeline-item variant="success" v-if="rs_pembayaran.status =='Valid'">
            <b-row>
              <b-col>
                <b-button size="sm" variant="outline-primary"> Tervalidasi
                </b-button>
              </b-col>
            </b-row>
          </app-timeline-item>
          <app-timeline-item variant="danger" v-if="rs_pembayaran.status =='Ditolak'">
            <b-row>
              <b-col>
                <h6>Status Pengajuan konformasi pembayaran tetolak</h6>
                <b-button size="sm" variant="outline-danger"> Ditolak
                </b-button>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
      </div>
    </b-alert>
  </div>
</template>

<script>
  import {
    BRow,
    BCol,
    BImg,
    BAvatar,
    BMedia,
    BButton,
    BCollapse,
    VBToggle,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    VBTooltip,
    BAlert
  } from 'bootstrap-vue'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      AppTimeline,
      BRow,
      BCol,
      AppTimelineItem,
      BImg,
      BMedia,
      BAvatar,
      BButton,
      BCollapse,
      BListGroup,
      BListGroupItem,
      BAvatarGroup,
      BBadge,
      BAlert,
    },
    directives: {
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
      Ripple
    },
    props: {
      rs_pembayaran: {},
      histori_pembayaran: {}
    }
  }
</script>