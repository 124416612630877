<template>
    <div>
        <b-row class="match-height ">
            <b-col md='6'>
                <b-card class="bg-gradient-warning" text-variant="center" @click="rs_loadData('Konfirmasi')">
                    <feather-icon size="20" icon="UserPlusIcon" class="text-white" />
                    <p class="card-text font-small-3"> Konfirmasi Pembayaran <br><strong  class="h1 text-white">{{ rs_jm.konfirmasi }}</strong></p>
                </b-card>
            </b-col>
            <b-col md='6'>
                <b-card class=" card-congratulations bg-warning" text-variant="center" @click="rs_loadData('Valid')">
                    <feather-icon size="20" icon="UserCheckIcon" class="text-white" />
                    <p class="card-text font-small-3"> Pengajuan tervalidasi<br> <strong class="h1 text-white">{{ rs_jm.valid }}</strong> </p>                
                </b-card>
            </b-col>
            <b-col md='6'>
                <b-card class="bg-gradient-info bg-info" text-variant="center" @click="rs_loadData('Revisi')">
                    <feather-icon size="20" icon="UserMinusIcon" class="text-white" />
                    <p class="card-text font-small-3"> Pengajuan Validasi Direvisi <br> <strong  class="h1 text-white">{{ rs_jm.revisi }}</strong></p>
                </b-card>
            </b-col>
            <b-col md='6'>
                <b-card class="bg-gradient-danger bg-danger" text-variant="center" @click="rs_loadData('Ditolak')">
                    <feather-icon size="20" icon="UserXIcon" class="text-white" />
                    <p class="card-text font-small-3"> Pengajuan Validasi Ditolak <br> <strong class="h1 text-white">{{ rs_jm.ditolak }}</strong></p>

                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BButton
    } from 'bootstrap-vue'
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BAvatar,
            BButton
        },
        props: {
            rs_jm: {}
        },
        emits: ['ReLoadData'],
        methods: {
            rs_loadData(val) {
                this.$emit('ReLoadData', val);
            }
        }
    }
</script>

<style>

</style>